import React from 'react';
import BrandSlat from '../../molecules/BrandSlat';
import ColorSlat from '../../molecules/ColorSlat';
import FontSlat from '../../molecules/FontSlat';
import LinkSlat from '../../molecules/LinkSlat';
import IconSlat from '../../molecules/IconSlat';
import LogoSlat from '../../molecules/LogoSlat';

import { Props } from './interfaces';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { app } from '../../../store/actions';
import isEqual from '../../../helpers/isEqual';
import {
  appSelector,
  modifiedSelector,
  originalSelector,
} from '../../../store/selectors';
import SideBar from '../../atoms/SideBar';

const BrandingSidebar: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  /**
   * Get Variables from store
   */
  const { publishingDone, validity, brandingLoaded } = useSelector(appSelector);
  // * for publish comparison
  const original = useSelector(originalSelector);
  const modified = useSelector(modifiedSelector);
  /**
   * Handle Publish
   */
  const handlePublish = () => {
    // * Check validity of slats before publishing
    // * get list of invalid slats
    const invalidSlats = Object.entries(validity).filter(
      ([slatName, isValid]) => !isValid,
    );
    // * if we have at least 1 invalid slat
    if (invalidSlats.length !== 0) {
      const slats = invalidSlats
        .map(([slatName, isValid]) => slatName)
        .join(', ');
      const s = invalidSlats.length > 1 ? `'s` : '';
      dispatch(
        app.addToast({
          kind: 'danger',
          title: 'Validation Error',
          description: `Information is missing from the following section${s}: ${slats}`,
          timeout: 5000,
        }),
      );
      return;
    }
    // if there are unsaved changes dispatch
    if (!isEqual(original, modified)) return dispatch(app.publishChanges());
    dispatch(
      app.addToast({
        title: 'Up To Date',
        description: 'There are no changes to publish!',
        kind: 'info',
        timeout: 5000,
      }),
    );
  };

  return (
    <SideBar
      resellerOnSubmit={() => {
        dispatch(app.fetchBranding());
      }}
      resellerDataLoading={!brandingLoaded}
      publishOnClick={handlePublish}
      publishing={!publishingDone}
    >
      <div className={styles.slat_container}>
        <BrandSlat />
        <LogoSlat />
        <ColorSlat />
        <FontSlat />
        <LinkSlat />
        <IconSlat />
      </div>
    </SideBar>
  );
};

export default React.memo(BrandingSidebar);
