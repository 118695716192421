import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faCommentAlt,
  faEdit,
  faExclamation,
  faFilm,
  faTrash,
  faUndoAlt,
  faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipEditModal from '../TooltipEditModal';
import { tooltipFrame } from '../../../helpers/tooltipFrame';
import {
  activeTooltipSelector,
  modifiedTooltipSelector,
} from '../../../store/selectors';
import { tooltips as tooltipActions } from '../../../store/actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Props } from './interfaces';
import { APITooltipElement } from '../../../types/Tooltips';
import styles from './styles.module.scss';
import Tooltip from '../../atoms/Tooltip/component';

const TooltipSidebarItem: React.FC<Props> = ({ tooltipNode }) => {
  const dispatch = useDispatch();
  const tooltipsFromApi = useSelector(activeTooltipSelector);
  const modifiedTooltips = useSelector(modifiedTooltipSelector);
  const [showEditModal, setShowEditModal] = useState(false);

  const defaultTooltipProperties: APITooltipElement = {
    type: 'tooltip',
    icon: 'info-circle',
  };
  /**
   * Tooltip item data from tooltip api
   */
  const apiData: APITooltipElement = {
    ...defaultTooltipProperties,
    ...(tooltipsFromApi[tooltipNode.id] || {}),
  };

  let icon;
  switch (apiData.type) {
    case 'tooltip':
      icon = faCommentAlt;
      break;
    case 'alert':
      icon = faExclamation;
      break;
    case 'video':
      icon = faFilm;
      break;
    case 'popup':
      icon = faWindowMaximize;
      break;
    default:
      icon = faExclamation;
      break;
  }

  const isModified = modifiedTooltips[tooltipNode.id] !== undefined;
  const existsOnApi = tooltipsFromApi[tooltipNode.id] !== undefined;

  return (
    <>
      <li
        className={`${styles.tooltip_item} ${
          isModified ? styles.modified : ''
        }`}
        onMouseEnter={() => {
          tooltipFrame.postMessage('highlight', {
            id: tooltipNode.id,
          });
        }}
        onMouseLeave={() => {
          tooltipFrame.postMessage('highlight', {
            id: tooltipNode.id,
          });
        }}
      >
        <div className={styles.type_icon}>
          <FontAwesomeIcon icon={icon as IconProp} title={apiData.type} />
        </div>
        <div className={styles.meta_data}>
          <span>{tooltipNode.label}</span>
        </div>
        <div className={styles.action_icons}>
          <Tooltip content="Revert Changes" disabled={!isModified}>
            <FontAwesomeIcon
              className={`${styles.action_icon} ${styles.danger} ${
                isModified ? '' : styles.hidden
              }`}
              icon={faUndoAlt}
              onClick={() => {
                dispatch(
                  tooltipActions.revertTooltip({
                    [tooltipNode.id]: null,
                  }),
                );
                tooltipFrame.postMessage('revert', { id: tooltipNode.id });
              }}
            />
          </Tooltip>
          <Tooltip content="Remove" disabled={!existsOnApi}>
            <FontAwesomeIcon
              className={`${styles.action_icon} ${styles.danger} ${
                existsOnApi ? '' : styles.hidden
              }`}
              icon={faTrash}
              onClick={() => {
                dispatch(
                  tooltipActions.updateTooltips({ [tooltipNode.id]: null }),
                );
                tooltipFrame.postMessage('update', { [tooltipNode.id]: null });
              }}
            />
          </Tooltip>
          <Tooltip content="Edit">
            <FontAwesomeIcon
              className={styles.action_icon}
              icon={faEdit}
              onClick={() => {
                setShowEditModal(true);
              }}
            />
          </Tooltip>
        </div>
      </li>
      {showEditModal && (
        <TooltipEditModal
          tooltipNode={tooltipNode}
          apiData={apiData}
          onClose={() => {
            setShowEditModal(false);
          }}
        />
      )}
    </>
  );
};

export default TooltipSidebarItem;
