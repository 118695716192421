import { Dispatch } from 'redux';
import { BRANDING_API } from '../../helpers/constants';
import { APITooltipElement } from '../../types/Tooltips';
import { tooltips } from '../actionTypes';
import { addToast } from '../app/actions';
import { AppState } from '../app/interfaces';
import { State } from '../interfaces';
import { TooltipAction } from './interfaces';

type ToastAction = { type: string; payload: { toast: AppState['toast'] } };
export const fetchingTooltips = () => {
  return {
    type: tooltips.fetching,
    payload: {},
  };
};
export const fetchTooltips = () => {
  return async (
    dispatch: Dispatch<TooltipAction | ToastAction>,
    getState: () => State,
  ) => {
    const { currentReseller } = getState().app;

    let data;
    try {
      dispatch(fetchingTooltips());
      let response: any = await fetch(BRANDING_API.TOOLTIP, {
        headers: {
          'X-Partner-ID': (currentReseller === -1
            ? 0
            : currentReseller
          ).toString(),
        },
      });
      response = await response.json();
      data = response.data.tooltips;
    } catch (err) {
      dispatch(
        addToast({
          title: 'Fetch tooltips',
          description: err.message,
          kind: 'danger',
          timeout: 3000,
        }),
      );
    } finally {
      dispatch({
        type: tooltips.fetch,
        payload: { keyedByApp: { ...data } },
      });
    }
  };
};

export const updateTooltips = (elements: {
  [key: string]: APITooltipElement | null;
}): TooltipAction => {
  return {
    type: tooltips.update,
    payload: { modified: { ...elements } },
  };
};

export const revertTooltip = (elements: {
  [key: string]: APITooltipElement | null;
}): TooltipAction => {
  return {
    type: tooltips.revert,
    payload: { modified: { ...elements } },
  };
};

export const publishingTooltips = () => {
  return { type: tooltips.publishing, payload: {} };
};
export const publishTooltips = () => {
  return async (
    dispatch: Dispatch<TooltipAction | ToastAction>,
    getState: () => State,
  ) => {
    const { accessToken, currentReseller } = getState().app;
    const { activeApp: appName, modified } = getState().tooltips;
    if (Object.keys(modified).length === 0) {
      dispatch(
        addToast({
          title: 'Publish tooltips',
          description: 'There are no changes to publish',
          kind: 'info',
          timeout: 3000,
        }),
      );
      return;
    }
    const body = {
      tooltips: modified,
    };
    let data;
    try {
      dispatch(publishingTooltips());
      let response: any = await fetch(`${BRANDING_API.TOOLTIP}/${appName}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-Partner-ID': (currentReseller === -1
            ? 0
            : currentReseller
          ).toString(),
        },
        body: JSON.stringify(body),
      });
      response = await response.json();
      if (response.code && response.code > 400) {
        throw new Error(response.message);
      }
      data = response.data.tooltips;
    } catch (err) {
      dispatch(
        addToast({
          title: 'Publish tooltips',
          description: err.message,
          kind: 'danger',
          timeout: 3000,
        }),
      );
    } finally {
      dispatch({
        type: tooltips.publish,
        payload: { original: data },
      });
      dispatch(
        addToast({
          title: 'Publish complete',
          description: 'Successfully published tooltips',
          kind: 'success',
          timeout: 3000,
        }),
      );
    }
  };
};

export const changeTooltipApp = (appName: string) => {
  return async (
    dispatch: Dispatch<TooltipAction | ToastAction>,
    getState: () => State,
  ) => {
    const { modified } = getState().tooltips;
    if (Object.keys(modified).length > 0) {
      const change = window.confirm('Changes that you made may not be saved.');
      if (!change) return;
    }
    dispatch({
      type: tooltips.changeApp,
      payload: { activeApp: appName },
    });
  };
};
