export const fonts = {
  selectFont: 'SELECT_FONT',
  selectFamily: 'SELECT_FONT_FAMILY',
  setFontImportUrl: 'SET_FONT_IMPORT_URL',
  selectTabularFont: 'SELECT_TABULAR_FONT',
  selectTabularFamily: 'SELECT_TABULAR_FONT_FAMILY',
  setTabularFontImportUrl: 'SET_TABULAR_FONT_IMPORT_URL',
  fetchGoogleFonts: 'FETCH_GOOGLE_FONTS',
  setFontWeightLight: 'SET_FONT_WEIGHT_LIGHT',
  setFontWeightRegular: 'SET_FONT_WEIGHT_REGULAR',
  setFontWeightSemibold: 'SET_FONT_WEIGHT_SEMIBOLD',
  setFontWeightBold: 'SET_FONT_WEIGHT_BOLD',
  revertFonts: 'REVERT_FONTS',
};
export const app = {
  login: 'LOGIN',
  logout: 'LOGOUT',
  setUserName: 'SET_USER_NAME',
  setUserAccessLevel: 'SET_USER_ACCESS_LEVEL',
  setResellerIds: 'SET_RESELLER_IDS',
  setAccessToken: 'SET_ACCESS_TOKEN',
  setTokenExpires: 'SET_TOKEN_EXPIRES',
  setCurrentReseller: 'SET_CURRENT_RESELLER',
  brandingRequested: 'BRANDING_REQUESTED',
  brandingLoaded: 'BRANDING_LOADED',
  publishingChanges: 'PUBLISHING_CHANGES',
  publishingDone: 'PUBLISHING_DONE',
  addToast: 'ADD_TOAST',
  removeToast: 'REMOVE_TOAST',
  updateValidity: 'UPDATE_VALIDITY',
  setActiveComponent: 'SET_ACTIVE_COMPONENT',
};
export const icons = {
  setColors: 'SET_ICON_COLORS',
  revertIcons: 'REVERT_ICONS',
};
export const colors = {
  setPrimaryColor: 'SET_PRIMARY_COLOR',
  setSecondaryColor: 'SET_SECONDARY_COLOR',
  setTopbarInverted: 'SET_TOPBAR_INVERTED',
  setSuccessColor: 'SET_SUCCESS_COLOR',
  setDangerColor: 'SET_DANGER_COLOR',
  setWarningColor: 'SET_WARNING_COLOR',
  setInfoColor: 'SET_INFO_COLOR',
  revertColors: 'REVERT_COLORS',
};
export const links = {
  setLinks: 'SET_LINKS',
  revertLinks: 'REVERT_LINKS',
};

export const root = {
  populateFields: 'POPULATE_FIELDS',
  saveChanges: 'SAVE_CHANGES',
  resetState: 'RESET_STATE',
};

export const brand = {
  setBrandName: 'SET_BRAND_NAME',
  setWebsite: 'SET_WEBSITE',
  revertBrand: 'REVERT_BRAND',
  setLogoFormData: 'SET_LOGO_FORM_DATA',
  setLogoSrc: 'SET_LOGO_SRC',
};

export const logos = {
  setProductLogo: 'SET_PRODUCT_LOGO',
  setCompanyLogo: 'SET_COMPANY_LOGO',
  revertLogos: 'REVERT_LOGOS',
};

export const productTour = {
  fetchProductTour: 'FETCH_PRODUCT_TOUR',
  branding: {
    setRun: 'SET_BRANDING_TOUR_RUN',
    setStep: 'SET_BRANDING_TOUR_STEP',
    saveTour: 'SAVE_BRANDING_TOUR',
  },
};

export const tooltips = {
  fetching: 'FETCHING_TOOLTIPS',
  fetch: 'FETCH_TOOLTIPS',
  update: 'UPDATE_TOOLTIPS',
  publishing: 'PUBLISHING_TOOLTIPS',
  publish: 'PUBLISH_TOOLTIPS',
  revert: 'REVERT_TOOLTIP',
  changeApp: 'CHANGE_TOOLTIP_APP',
};
