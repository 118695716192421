import { State } from './interfaces';
import { AnyAction, Reducer } from 'redux';
import { root } from './actionTypes';
import combinedReducers from './combinedReducers';
import mapBranding from '../helpers/mapBranding';
import { initialFontState } from './fonts/reducers';
import { initialAppState } from './app/reducers';
import { initialIconState } from './icons/reducers';
import { initialColorState } from './colors/reducers';
import { initialLinkState } from './links/reducers';
import { initialBrandState } from './brand/reducers';
import { initialLogoState } from './logos/reducers';
import { initialProductTourState } from './productTour/reducers';
import { initialTooltipState } from './tooltips/reducers';

const initialState: State = {
  app: initialAppState,
  fonts: initialFontState,
  icons: initialIconState,
  colors: initialColorState,
  links: initialLinkState,
  brand: initialBrandState,
  logos: initialLogoState,
  originalBranding: { data: {} },
  productTour: initialProductTourState,
  tooltips: initialTooltipState,
};

const resetState = () => {
  return { ...initialState };
};
// populate state tree with values from API
const populateFields = (state: State, payload: AnyAction) => {
  const { fetchedBranding } = payload;
  const newState = mapBranding.APIToStore({ state, fetchedBranding });
  return {
    ...state,
    ...newState,
  };
};
const saveChanges = (state: State) => {
  const { brand, colors, fonts, icons, links, logos } = state;

  return {
    ...state,
    brand: { ...brand, original: brand.modified },
    colors: { ...colors, original: colors.modified },
    fonts: { ...fonts, original: fonts.modified },
    icons: { ...icons, original: icons.modified },
    links: { ...links, original: links.modified },
    logos: { ...logos, original: logos.modified },
  };
};

const rootReducer: Reducer = (state: State, action: any) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case root.populateFields:
      return populateFields(state, action.payload);
    case root.saveChanges:
      return saveChanges(state);
    case root.resetState:
      return resetState();
    default:
      return combinedReducers(state, action);
  }
};

export default rootReducer;
