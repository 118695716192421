import { app } from '../actionTypes';
import { AppState, AppAction } from './interfaces';
import { Reducer } from 'redux';

const login = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    isLoggedIn: payload.isLoggedIn ?? state.isLoggedIn,
  };
};
const logout = (state: AppState, payload: AppAction['payload']) => {
  const token = 'branding-interface-token';
  const expires = token + '-expires';
  const remember = token + '-remember';
  localStorage.setItem(token, '');
  localStorage.setItem(expires, '');
  localStorage.setItem(remember, '');
  return {
    ...state,
    isLoggedIn: payload.isLoggedIn ?? state.isLoggedIn,
  };
};
const setUserName = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    userName: payload.userName || state.userName,
  };
};
const setUserAccessLevel = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    userAccessLevel: payload.userAccessLevel || state.userAccessLevel,
  };
};

const setResellerIds = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    resellerIds: payload.resellerIds || state.resellerIds,
    currentReseller: payload.resellerIds?.[0] || -1,
  };
};

const setCurrentReseller = (state: AppState, payload: AppAction['payload']) => {
  let currentReseller = payload.currentReseller ?? -1;
  if (isNaN(currentReseller)) {
    currentReseller = -1;
  }
  return {
    ...state,
    currentReseller,
  };
};

const setAccessToken = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    accessToken: payload.accessToken || state.accessToken,
  };
};

const setTokenExpires = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    tokenExpires: payload.tokenExpires || state.tokenExpires,
  };
};

const brandingRequested = (state: AppState) => {
  return {
    ...state,
    brandingLoaded: false,
  };
};
const brandingLoaded = (state: AppState) => {
  return {
    ...state,
    brandingLoaded: true,
  };
};

const publishingChanges = (state: AppState) => {
  return {
    ...state,
    publishingDone: false,
  };
};
const publishingDone = (state: AppState) => {
  return {
    ...state,
    publishingDone: true,
  };
};
const addToast = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    toast: payload.toast || state.toast,
  };
};
const removeToast = (state: AppState) => {
  return {
    ...state,
    toast: undefined,
  };
};
const updateValidity = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    validity: {
      ...state.validity,
      ...payload.validity,
    },
  };
};

const setActiveComponent = (state: AppState, payload: AppAction['payload']) => {
  return {
    ...state,
    activeComponent: payload.activeComponent || state.activeComponent,
  };
};

export const initialAppState: AppState = {
  isLoggedIn: false,
  userName: '',
  userAccessLevel: '',
  resellerIds: [],
  currentReseller: -1,
  brandingLoaded: true,
  publishingDone: true,
  accessToken: '',
  tokenExpires: '',
  validity: {
    brand: false,
    logos: false,
    colors: false,
    fonts: false,
    links: false,
    icons: false,
  },
  activeComponent: 'sidebar',
};
const reducer: Reducer<AppState, AppAction> = (
  state: AppState = initialAppState,
  action: AppAction,
) => {
  if (!action) {
    return { ...state };
  }
  switch (action.type) {
    case app.login:
      return login(state, action.payload);
    case app.logout:
      return logout(state, action.payload);
    case app.setUserName:
      return setUserName(state, action.payload);
    case app.setUserAccessLevel:
      return setUserAccessLevel(state, action.payload);
    case app.setResellerIds:
      return setResellerIds(state, action.payload);
    case app.setCurrentReseller:
      return setCurrentReseller(state, action.payload);
    case app.setAccessToken:
      return setAccessToken(state, action.payload);
    case app.setTokenExpires:
      return setTokenExpires(state, action.payload);
    case app.brandingRequested:
      return brandingRequested(state);
    case app.brandingLoaded:
      return brandingLoaded(state);
    case app.publishingChanges:
      return publishingChanges(state);
    case app.publishingDone:
      return publishingDone(state);
    case app.addToast:
      return addToast(state, action.payload);
    case app.removeToast:
      return removeToast(state);
    case app.updateValidity:
      return updateValidity(state, action.payload);
    case app.setActiveComponent:
      return setActiveComponent(state, action.payload);
    default:
      return { ...state };
  }
};
export default reducer;
