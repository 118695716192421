import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Props } from './interfaces';

import SideBar from '../../atoms/SideBar';
import TooltipSidebarItem from '../../molecules/TooltipSidebarItem';
import { tooltipFrame } from '../../../helpers/tooltipFrame';
import { tooltips } from '../../../store/actions';
import { tooltipStateSelector } from '../../../store/selectors';
import { NvComponentTooltipElement } from '../../../types/Tooltips';
import styles from './styles.module.scss';

const TooltipSidebar: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { fetching, publishing } = useSelector(tooltipStateSelector);
  const [tooltipNodes, setTooltipNodes] = useState<NvComponentTooltipElement[]>(
    [],
  );

  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      if (typeof e.data === 'string' && e.data.includes('setImmediate')) {
        return;
      }
      if (e.data.source !== 'tooltip-provider') return;
      setTooltipNodes(e.data.detail);
      tooltipFrame.window = e.source as Window;
    };
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  const dedupedTooltipNodes = tooltipNodes.reduce<NvComponentTooltipElement[]>(
    (acc, curr) => {
      const nodeExists = acc.findIndex(node => node.id === curr.id);
      if (nodeExists === -1) {
        return [...acc, curr];
      }
      return acc;
    },
    [],
  );

  return (
    <SideBar
      resellerOnSubmit={() => {
        dispatch(tooltips.fetchTooltips());
      }}
      resellerDataLoading={fetching}
      publishing={publishing}
      publishOnClick={() => {
        dispatch(tooltips.publishTooltips());
      }}
    >
      <ul className={styles.tooltip_item_container}>
        {dedupedTooltipNodes.map(ttn => {
          return <TooltipSidebarItem key={ttn.id} tooltipNode={ttn} />;
        })}
      </ul>
    </SideBar>
  );
};

export default React.memo(TooltipSidebar);
