import { State } from '../store/interfaces';
import { fontStringToArray, fontArrayToString } from './fonts';
export interface BrandingInfo {
  // apiRoot: string;
  brand: string;
  partnerId: string;
  website: string;
  companyLogoSrc?: string; // * not required in request
  productLogoSrc?: string; // * not required in request

  branding: {
    supportEmail: string;
    supportPhone: string;
    termsUrl: string;
    refundsUrl: string;
    contactsUrl: string;
    helpUrl: string;
    // forgotPasswordLocation: string;
    twitter: string;
    twitterUrl: string;
    // signUpUrl: string;

    // styles
    fontFamily: string;
    fontImportUrl: string;
    fontTabularFamily?: string;
    fontTabularImportUrl?: string;
    colorPrimary: string;
    colorSecondary: string;
    colorSuccess: string;
    colorDanger: string;
    colorWarning: string;
    colorInfo: string;
    fontWeights: {
      light: string;
      regular: string;
      semibold: string;
      bold: string;
    };
    // flags
    topbarInverted: boolean;
    [name: string]: string | object | boolean | undefined;
  };

  apps: {
    [name: string]: {
      color1: string;
      color2: string;
      favicons: { name: string; location: string }[];
      // url: string;
    };
  };
}

const storeToAPI = (state: State) => {
  // BRAND
  const { brandName, website } = state.brand.modified;
  const brand = {
    brand: brandName,
    website,
  };
  // FONTS
  const {
    fontFamily,
    fontWeights,
    fontImportUrl,
    tabularFontFamily,
    tabularFontImportUrl,
  } = state.fonts.modified;
  // const category =
  //   selectedFont?.category === 'display' ||
  //   selectedFont?.category === 'handwriting'
  //     ? 'cursive'
  //     : selectedFont?.category;
  let fontTabularFamily: string | undefined = fontArrayToString(
    tabularFontFamily,
  );
  fontTabularFamily =
    fontTabularFamily === "''" ? undefined : fontTabularFamily;
  const fonts = {
    fontFamily: fontArrayToString(fontFamily),
    fontImportUrl,
    fontWeights,
    fontTabularFamily,
    fontTabularImportUrl: tabularFontImportUrl || undefined,
  };
  // LINKS
  const links = { ...state.links.modified };
  // COLORS
  const {
    primary,
    secondary,
    topbarInverted,
    success,
    danger,
    warning,
    info,
  } = state.colors.modified;
  const colors = {
    colorPrimary: primary,
    colorSecondary: secondary,
    topbarInverted,
    colorSuccess: success,
    colorDanger: danger,
    colorWarning: warning,
    colorInfo: info,
  };
  // APPS (ICONS)
  const appIcons = state.icons.modified;
  let apps = {};
  for (let appName in appIcons) {
    const color1 = appIcons[appName].color1.replace('#', '');
    const color2 = appIcons[appName].color2.replace('#', '');
    apps = {
      ...apps,
      [appName]: {
        color1,
        color2,
      },
    };
  }

  const mappedStoreToAPI: BrandingInfo = {
    ...state.originalBranding.data,
    partnerId: `${state.app.currentReseller}`,
    ...brand,
    branding: {
      ...state.originalBranding.data.branding,
      ...fonts,
      ...colors,
      ...links,
    },
    apps,
  };
  return mappedStoreToAPI;
};

const APIToStore = ({
  state,
  fetchedBranding,
}: {
  state: State;
  fetchedBranding: BrandingInfo;
}) => {
  // BRAND
  const { brand: brandName, website } = fetchedBranding;
  const brand = {
    brandName,
    website,
  };
  // LOGOS
  const { companyLogoSrc, productLogoSrc } = fetchedBranding;
  const logos = {
    company: {
      logoSrc: companyLogoSrc,
    },
    product: {
      logoSrc: productLogoSrc,
    },
  };
  // FONTS
  const {
    fontWeights,
    fontFamily,
    fontImportUrl,
    fontTabularFamily,
    fontTabularImportUrl,
  } = fetchedBranding.branding;

  const fonts = {
    fontFamily: fontStringToArray(fontFamily),
    fontWeights,
    fontImportUrl,
    tabularFontFamily: fontTabularFamily
      ? fontStringToArray(fontTabularFamily)
      : [''],
    tabularFontImportUrl: fontTabularImportUrl || '',
  };
  // COLORS
  const {
    colorPrimary,
    colorSecondary,
    topbarInverted,
    colorSuccess,
    colorDanger,
    colorWarning,
    colorInfo,
  } = fetchedBranding.branding;
  const colors = {
    primary: colorPrimary,
    secondary: colorSecondary,
    topbarInverted,
    success: colorSuccess,
    danger: colorDanger,
    warning: colorWarning,
    info: colorInfo,
  };
  // LINKS
  const wantedProperties = [
    'supportEmail',
    'supportPhone',
    'termsUrl',
    'refundsUrl',
    'contactsUrl',
    'helpUrl',
    'twitter',
    'twitterUrl',
  ];
  let links = {};
  for (let key in fetchedBranding.branding) {
    if (wantedProperties.includes(key)) {
      links = { ...links, [key]: fetchedBranding.branding[key] };
    }
  }
  // ICONS
  let icons = {};
  for (let app in fetchedBranding.apps) {
    icons = {
      ...icons,
      [app]: {
        color1: fetchedBranding.apps[app].color1,
        color2: fetchedBranding.apps[app].color2,
      },
    };
  }

  const mappedAPIToStore = {
    fonts: {
      ...state.fonts,
      original: {
        ...fonts,
      },
      modified: {
        ...fonts,
      },
    },
    icons: {
      original: {
        ...icons,
      },
      modified: {
        ...icons,
      },
    },
    colors: {
      original: {
        ...colors,
      },
      modified: {
        ...colors,
      },
    },
    links: {
      original: {
        ...links,
      },
      modified: {
        ...links,
      },
    },
    brand: {
      original: {
        ...brand,
      },
      modified: {
        ...brand,
      },
    },
    logos: {
      original: {
        ...logos,
      },
      modified: {
        ...logos,
      },
    },
    originalBranding: {
      data: fetchedBranding,
    },
  };
  return mappedAPIToStore;
};

export default { storeToAPI, APIToStore };
