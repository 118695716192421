import React from 'react';
import { useSelector } from 'react-redux';
import { NvTopbar } from 'nv-react-components-v2';
import iconSrcGenerator from '../../../../helpers/iconSrcGenerator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import {
  modifiedBrandSelector,
  modifiedColorsSelector,
  modifiedIconsSelector,
  modifiedLogosSelector,
} from '../../../../store/selectors';

const TopBar = ({
  appName,
  showHamburger = false,
  onHamburgerClick = () => {},
}: {
  appName: string;
  showHamburger?: boolean;
  onHamburgerClick?: () => void;
}): JSX.Element => {
  const { brandName } = useSelector(modifiedBrandSelector);
  const logos = useSelector(modifiedLogosSelector);
  const icons = useSelector(modifiedIconsSelector);
  const { topbarInverted } = useSelector(modifiedColorsSelector);
  const iconSrc = iconSrcGenerator(icons);
  const apps = [
    { label: 'Manager', onClick: () => {}, icon: iconSrc.manager },
    {
      label: 'Analytics',
      onClick: () => {},
      icon: iconSrc.analytics,
    },
    {
      label: 'Communicator',
      onClick: () => {},
      icon: iconSrc.communicator,
    },
  ];
  const userMenuItems = [
    {
      label: 'User Settings',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faUserCircle} />,
    },
    {
      label: 'Logout',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
    },
  ];

  const prettyNames: { [key: string]: string } = {
    manager: 'Manager',
    analytics: 'Analytics',
    communicator: 'Communicator',
  };

  return (
    <NvTopbar
      resellerName={brandName}
      brandSrc={logos.product.logoSrc || logos.company.logoSrc}
      product={prettyNames[appName]}
      apps={apps}
      userMenuItems={userMenuItems}
      inverted={topbarInverted}
      showLeftHamburger={showHamburger}
      onHamburgerClick={onHamburgerClick}
    />
  );
};

export default TopBar;
