import { State } from './interfaces';
import isEqual from '../helpers/isEqual';

export const appSelector = (state: State) => state.app;
export const validitySelector = (state: State) => state.app.validity;
export const modifiedBrandSelector = (state: State) => state.brand.modified;
export const modifiedColorsSelector = (state: State) => state.colors.modified;
export const modifiedFontsSelector = (state: State) => state.fonts.modified;
export const modifiedIconsSelector = (state: State) => state.icons.modified;
export const modifiedLinksSelector = (state: State) => state.links.modified;
export const modifiedLogosSelector = (state: State) => state.logos.modified;

export const originalColorsSelector = (state: State) => state.colors.original;

export const originalSelector = (state: State) => {
  return {
    ...state.colors.original,
    ...state.icons.original,
    ...state.fonts.original,
    ...state.links.original,
    ...state.brand.original,
    ...state.logos.original,
  };
};
export const modifiedSelector = (state: State) => {
  return {
    ...state.colors.modified,
    ...state.icons.modified,
    ...state.fonts.modified,
    ...state.links.modified,
    ...state.brand.modified,
    ...state.logos.modified,
  };
};

export const brandingIsDirtySelector = (state: State) => {
  const modified = modifiedSelector(state);
  const original = originalSelector(state);
  return !isEqual(original, modified);
};

export const productTourSelector = (state: State) => {
  return state.productTour;
};

export const tooltipStateSelector = (state: State) => {
  return state.tooltips;
};
export const activeTooltipSelector = (state: State) => {
  return { ...state.tooltips.original, ...state.tooltips.modified };
};

export const modifiedTooltipSelector = (state: State) => {
  return { ...state.tooltips.modified };
};

export const tooltipIsDirtySelector = (state: State) => {
  const modified = modifiedTooltipSelector(state);
  return Object.keys(modified).length > 0;
};
