import { IconState } from '../store/icons/interfaces';

const iconSrcGenerator = (icons: IconState['modified']) => {
  let iconSrc = { manager: '', analytics: '', communicator: '' };
  const iconSrcBase = 'https://my.nimvelo.com';
  //iterate through icons object
  for (const app in icons) {
    const appObj = icons[app];
    // remove # from color
    const color1 = appObj.color1.replace('#', '');
    const color2 = appObj.color2?.replace('#', '');
    if (color1) {
      iconSrc = {
        ...iconSrc,
        [app]: `${iconSrcBase}/branding/icons?name=${app}&color1=${color1}${
          color2 && `&color2=${color2}`
        }`,
      };
    }
  }
  return iconSrc;
};
export default iconSrcGenerator;
